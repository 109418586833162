import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query Ramadan {
    heroImage: file(relativePath: {eq: "project-ramadan-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Ramadan Baskets: The best actions during the best of the months." description="Whoever feeds the person who is breaking his fast, he will have his reward (for his fasting) without decreasing anything from the reward of the fasting person." mdxType="SEO" />
    <CauseHero purpose="ramadan" title="Ramadan Baskets" text="The best actions during the best of the months." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <p>{`The Prophet, peace and blessings be upon him, said, `}<br />{`
`}<strong parentName="p">{`« Whoever feeds the person who is breaking his fast, he will have his reward (for his fasting) without decreasing anything from the reward of the fasting person ».`}</strong></p>
      <p>{`Ramadan is the month of mercy and blessings. But while we spend our days fasting to finally find a meal to break our fast, there are brothers and sisters around the world who cannot afford that. By donating to our RAMADAN BASKETS program you are not only doing a good action during the best of the months, but also as per the hadith you will have the rewards of your fasting as well as the ones for the fasting of the people you are feeding. UMMATY RAMADAN BASKETS are prepared as per the eating habits of each country we target. `}<strong parentName="p">{`Each basket`}</strong>{` includes enough `}<strong parentName="p">{`diversified food items that can suffice for a family of 5 during the full month.`}</strong></p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      